import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/v2/templates/Layout';
import Grid from '../../components/v1/atoms/grid';
import useStoryblok from '../../lib/storyblok';
import Bloks from '../../components/atoms/Bloks';
import { Section } from '../../components/v2/templates/Section';
import IntroduceYourselfForm from '../../components/v2/molecules/Form/introduceYourself';
import SofaIllustration from '../../images/Sofa_illustration.svg';
import TrustpilotLogo from '../../images/TrustpilotLogo.svg';
import TrustpilotStars from '../../images/TrustpilotStars.svg';
import DynamicComponent from '../../components/dynamicComponent';
import { StoryblokPage } from '../../types';
import cn from 'classnames';

const GuarantidHome: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout
      location={location}
      headerData={content.header}
      footerData={content.footer}
      hideNavMenu
      light={false}
      headerVisibleButtonIndex={2}
    >
      <Bloks bloks={content.SEO} />
      <Section curved hero className="py-10" noGrid curveColor="primary">
        <Grid className="relative">
          <div className="col-span-full text-center lg:text-left lg:col-span-6 lg:col-start-1 text-white mt-10">
            <h1
              className={cn(
                'text-3xl font-serif md:text-6xl text-white mb-6 text-center lg:text-left',
              )}
            >
              {content.title}
            </h1>
            <p className="text-base md:text-2xl pb-6 text-center lg:text-left">
              {content.description}
            </p>

            {/* //TODO replace this with a widget ASAP instead of static image :/  */}
            <span className="inline-flex items-center flex-wrap justify-center">
              <span className="mb-1 md:mb-0 inline-flex items-center justify-center">
                <span className="text-xl md:text-2xl  font-semibold">Excellent</span>
                <img height={27} src={TrustpilotStars} alt="4.5/5 star rating" className="px-2" />
              </span>

              <span className="inline-flex items-center justify-center">
                <span className="font-semibold text-base md:text-lg">260 reviews on</span>
                <img height={22} src={TrustpilotLogo} alt="TrustPilot Logo" className="px-2" />
              </span>
            </span>
          </div>
          <div className="col-span-full mt-10 lg:absolute lg:right-0 lg:top-[10px]">
            <IntroduceYourselfForm
              className="sm:max-w-none w-full col-span-4 sm:col-span-8 lg:col-start-2 lg:col-end-7 lg:shadow-2xl"
              queryParams={location.search}
              analyticsInfo="marketing - guarantid"
              buttonLabel="Get my guarantor"
              formTitle="Get your guarantor today!"
            />
          </div>
        </Grid>
      </Section>

      <DynamicComponent blok={{ ...content.sections[0] }} />
      <DynamicComponent blok={{ ...content.sections[1] }} />
      <DynamicComponent blok={{ ...content.sections[2] }} />

      <Section
        curved
        noGrid
        className="mb-33 bg-blue lg:!mt-20 lg:!pt-0"
        curveColor="primary"
        id="ApplyNow"
      >
        <Grid className="relative pb-[270px] sm:pb-[290px] lg:p-0">
          <IntroduceYourselfForm
            className="sm:max-w-none w-full col-span-4 sm:col-span-8 lg:col-start-2 lg:col-end-7 mt-11 lg:shadow-2xl lg:mt-25 lg:mb-9"
            queryParams={location.search}
            analyticsInfo="marketing - guarantid"
            formTitle="Get your guarantor today!"
          />
          <img
            src={SofaIllustration}
            alt="sofa with flying papers from the seat"
            className="absolute -bottom-20  left-3.5 w-[375px] sm:w-[343px] lg:w-[550px] xl:w-[681] sm:right-5 sm:left-auto sm:-bottom-16"
            loading="lazy"
          />
        </Grid>
      </Section>

      <DynamicComponent blok={{ ...content.sections[3] }} />
      <DynamicComponent blok={{ ...content.sections[4] }} />
    </Layout>
  );
};

export default GuarantidHome;

export const query = graphql`
  query GuarantidHomeQuery {
    storyblokEntry(full_slug: { eq: "guarantid-home" }) {
      content
      name
    }
  }
`;
